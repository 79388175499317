import React, { useEffect, useState } from 'react'
import { BASE } from './../../basedata/constants'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

const DocumentPassportMessage = ({ onResponse }: Props) => {

  const resetProccess = async () => {

   const currentToken:any = window.sessionStorage.getItem('token')

    return fetch(`${BASE.api.base_url}${BASE.api.invalidate_document}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        token: currentToken,
      })
    })
      .then((resg) => resg.json())
      .then((responseSave) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('doc', 'false');
        window.location.search = searchParams.toString();
        onResponse({ step: 'faceproceed' })
      })
      .catch((error) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('doc', 'false');
        window.location.search = searchParams.toString();
        onResponse({ step: 'faceproceed' })
      })
  }



  useEffect(() => {
    window.gtag('event', 'Onboarding: Document Error', {
      'event_category': 'onboarding',
      'event_label': 'error_document',
      'value': 1
    });
  }, [])



  return (
    <>
      <h1>
      <span>Analisando</span> Documentos
      </h1>
      <hr />
      <p>
      Seus documentos estão sendo validados.<br/>
      Qualquer dúvida, entre em contato no e-mail <a className='info-link' href="mailto:biometria@flamengo.com.br">biometria@flamengo.com.br</a> para acompanhar a análise do seu cadastro biométrico.
      </p>
    </>
  )
}

export default DocumentPassportMessage
